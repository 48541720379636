import axios from 'axios';
import {
  Button, Dialog, DialogContent, DialogTitle,
  ListItem, ListItemText, List, ListItemIcon, IconButton,
  DialogActions, TextField as MuiTextField,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {
  Datagrid, SaveButton, SimpleForm, TextField, TextInput, Toolbar, useNotify,
  useRecordContext, useRefresh, useTranslate
} from 'react-admin';
import handleError from './handleError';
import { Cancel } from '@mui/icons-material';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  listCell: {
    textAlign: 'left',
    width: '100%',
    maxWidth: 360,
    padding: '0 0'
  },
  item: {
    padding: 0,
  },
}));

const UpdatePortState = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const notify = useNotify();
  const state = record.osPortStatus;
  const portId = record.osPortId;

  const handleClick = async (state) => {
    try {
      const options = {
        headers: {},
      };

      const sendData = {
        state
      };

      const token = localStorage.getItem('token');
      options.headers.Authorization = `Bearer ${token}`;

      const res = await axios.put(`/api/v1/networks/ports/${portId}`, sendData, options);
      if (res.status !== 200) {
        notify(translate('app.networks.ports.errorMessage'), { type: 'error' });
      }
      else {
        notify(translate('app.trainings.ports.successMessage'), { type: 'success' });
      };
    } catch (error) {
      handleError(error, notify);
    };
  };

  if (state === "ACTIVE") {
    return (<Button onClick={() => handleClick(false)}> {translate('app.networks.ports.disable')} </Button>);
  } else {
    return (<Button onClick={() => handleClick(true)}> {translate('app.networks.ports.enable')} </Button>);
  };
};


const UpdatePortDnsName = () => {
  const record = useRecordContext(); // Get the current port record
  const translate = useTranslate();
  const notify = useNotify();
  const portId = record.osPortId;

  const [open, setOpen] = useState(false);
  const [dnsName, setDnsName] = useState(record.dns_name || '');

  const handleClick = async () => {
    try {
      const options = {
        headers: {},
      };

      const token = localStorage.getItem('token');
      options.headers.Authorization = `Bearer ${token}`;

      const sendData = {
        dnsName, // The new DNS name
      };

      const res = await axios.put(`/api/v1/networks/ports/${portId}/updateDnsName`, sendData, options);

      if (res.status === 200) {
        notify(translate('app.networks.ports.dnsNameUpdated'), { type: 'success' });
        setOpen(false); // Close the dialog
      } else {
        notify(translate('app.networks.ports.dnsNameUpdateError'), { type: 'error' });
      }
    } catch (error) {
      notify(translate('app.networks.ports.dnsNameUpdateError'), { type: 'error' });
    }
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        {translate('app.networks.ports.updateDnsName')}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{translate('app.networks.ports.updateDnsName')}</DialogTitle>
        <DialogContent>
        <MuiTextField
            label={translate('app.networks.ports.dnsName')}
            value={dnsName}
            onChange={(e) => setDnsName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>
            {translate('app.networks.ports.cancel')}
          </Button>
          <Button onClick={handleClick} color="primary">
            {translate('app.networks.ports.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};


const AllowedAdressPairs = () => {

  const notify = useNotify();
  const record = useRecordContext();
  const refresh = useRefresh();
  const translate = useTranslate();
  const [showDialog, setShowDialog] = useState(false);
  const portId = record.osPortId;

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const Validate = (values) => {
    const errors = {};
    if (!values.ipAddress) errors.ipAddress = 'ra.validation.required';
    return errors;
  };

  const CreateToolbar = () => {
    return (
      <Toolbar>
        <Button color='primary' label="ra.action.cancel" size="medium" onClick={handleCloseClick} sx={{ border: '1px solid #6b489d' }}>
          <Cancel />
        </Button>
        <SaveButton label={translate('app.buttons.send')} sx={{ marginLeft: 2 }} />
      </Toolbar>
    );
  };

  const addAllowedAddressPairs = async (data) => {
    try {

      const options = {
        headers: {},
      };

      const sendData = { ipAddress: data.ipAddress };
      const token = localStorage.getItem('token');
      options.headers.Authorization = `Bearer ${token}`;

      const res = await axios.put(`/api/v1/networks/ports/${portId}/allowAddressPairs`, sendData, options);

      if (res.status !== 200) {
        notify(translate('app.networks.ports.allowErrorMessage'), { type: 'error' });
      }
      else {
        notify(translate('app.networks.ports.allowSuccessMessage'), { type: 'success' });
        handleCloseClick();
        refresh();
      };
    }
    catch (error) {
      handleError(error, notify);
    };
  };

  return (
    <>
      <Button color="primary" onClick={handleClick}>
        {translate('app.networks.ports.allowAddressPairs')}
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('app.networks.ports.allowAddressPairs')}
      >
        <DialogTitle>{translate('app.networks.ports.allowAddressPairs')}</DialogTitle>
        <DialogContent>
          <SimpleForm validate={Validate} toolbar={<CreateToolbar />} onSubmit={addAllowedAddressPairs} >
            <TextInput source="ipAddress" />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  )
};

const RemoveAddressPairs = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const portId = record.osPortId;

  const handleClick = async (state) => {
    try {
      const options = {
        headers: {},
      };

      const sendData = {
        state
      };

      const token = localStorage.getItem('token');
      options.headers.Authorization = `Bearer ${token}`;

      const res = await axios.put(`/api/v1/networks/ports/${portId}/removeAddressPairs`, sendData, options);
      if (res.status !== 200) {
        notify(translate('app.networks.ports.removeErrorMessage'), { type: 'error' });
      }
      else {
        notify(translate('app.networks.ports.removeSuccessMessage'), { type: 'success' });
        refresh();
      };
    } catch (error) {
      handleError(error, notify);
    };
  };


  return (<Button onClick={() => handleClick(false)}> {translate('app.networks.ports.removeAddressPairs')} </Button>);

};

const ListActionButtons = (props) => {
  const record = useRecordContext();
  return (
    <>
      <UpdatePortState />
      {/* <UpdatePortDnsName /> New button for updating DNS name */}
    </>
  );
};


const AddressField = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const sort = { field: 'id', order: 'ASC' };

  if (record && record.netifs) {
    const netifs = record.netifs;

    let filteredNetwork = netifs.filter((netif) => {
      return netif.addressIp;
    });

    const AddressPairsField = () => {
      const record = useRecordContext();

      if (record.addressPairs !== "") {
        return (<TextField source="addressPairs" label={translate('app.networks.ports.addressPairs')} />)
      } else { return (<> None </>) }

    };

    return (
      <>
        <Datagrid data={filteredNetwork} sort={sort}>
          <TextField source="addressIp" />
          <TextField source="osPortStatus" label={translate('app.networks.ports.state')} />
          <AddressPairsField label={translate('app.networks.ports.addressPairs')} />
          {/* <TextField source="dnsName" label="DNS Name" />
          <TextField source="fqdn" label="FQDN" /> */}
          <ListActionButtons podId={record.pod} />
        </Datagrid>
      </>
    );
  }
  else return null;
};

const ShowIpAddress = () => {
  const record = useRecordContext();
  const sort = { field: 'id', order: 'ASC' };
  const classes = useStyles();

  if (record && record.netifs) {
    const netifs = record.netifs;
  
    // Combine both fixed and floating IPs into one array
    let filteredNetwork = netifs.filter((netif) => {
      return netif.addressIp || netif.floating_ip_address;
    });
  
    // Sort: Fixed IPs first, then Floating IPs
    filteredNetwork.sort((a, b) => {
      if (a.type === 'fixed' && b.type === 'floating') return -1;
      if (a.type === 'floating' && b.type === 'fixed') return 1;
      return 0;
    });
  
    return (
      <>
        <List dense classes={{ root: classes.listCell }}>
          {filteredNetwork.map((netif) => {
            // Determine the display IP and type (Fixed or Floating)
            const displayIp = netif.addressIp || netif.floating_ip_address;
            const ipType = netif.type === 'floating' ? 'Public IP' : 'Internal IP';
  
            return (
              <ListItem key={displayIp} classes={{ root: classes.item }} disablePadding>
                <ListItemIcon sx={{ minWidth: '20px' }}>
                  <IconButton size="small">
                    <CircleIcon fontSize="small" sx={{ fontSize: '0.50rem' }} />
                  </IconButton>
                </ListItemIcon>
                <ListItemText
                  primary={displayIp}
                  secondary={ipType} // Add type as a secondary text
                />
              </ListItem>
            );
          })}
        </List>
      </>
    );
  }    
  else return null;
};


export { AddressField, ShowIpAddress };