import { Loading } from 'react-admin';
import Loadable from 'react-loadable';

const FirewallList = Loadable({ loader: () => import('./firewallList'), loading: Loading });
const FirewallCreate = Loadable({ loader: () => import('./firewallCreate'), loading: Loading });
const FirewallShow = Loadable({ loader: () => import('./firewallShow'), loading: Loading });
// const FirewallEdit = Loadable({ loader: () => import('./firewallEdit'), loading: Loading });

export default { 
  list: FirewallList,
  create: FirewallCreate,
  show: FirewallShow,
  // edit: FirewallEdit
};